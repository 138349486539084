import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { InsightsCard } from './InsightsCard';
import { EInsights, TAllPersonality } from '../../store/reducers/UserInfoReducer';
import { Text } from '../../Components/General/Text';
import { BrancherDivider } from '../../Components/General/BrancherDivider';
import { PageLayout } from '../../Components/General/PageLayout';
import { PersonalityScaler } from './PersonalityScaler';
import { EValues } from '../../Form/Values/ValuesFormConfig';
import { BrancherRadarChart } from '../../Components/General/BrancherRadarChart';
import { SimpleMatchInsights } from './SimpleMatchInsights';
import { Colors } from '../../consts/colors';
import { BrancherCard } from '../../Components/General/BrancherCard';
import { BrancherLinkText } from '../../Components/General/BrancherLinkText';
import { AcceptButton, RejectButton } from '../../Components/InputFields/BrancherButton';
import { BrancherDialog } from '../../Components/General/BrancherDialog';

const useStyles = makeStyles({
  flatCard: {
    borderRadius: 15,
    padding: 20,
    background: Colors.white,
  },
});

interface IMatchInsights {
  match?: boolean;
}

export const MatchInsights: React.FC<IMatchInsights> = ( { match } ) => {
  const paid = true;
  const [openRequestUnlock, setOpenRequestUnlock] = React.useState<boolean>(false);
  const [unlocked, setUnlocked] = React.useState<boolean>(false); // for testing locally
  // const paid = useSelector((state: IStoreTypes) => state.user?.paidInsights);
  const styles = useStyles();
  // const pairInsights = useSelector((state: IStoreTypes) => state.user?.sessionPairInsights);

  const setSendOpenRequestUnlock = () => {
    setUnlocked(true);
    setOpenRequestUnlock(false);
    // make api call out to new API that puts a flag on the user profile that says they want to see insights
  };

  const actualPaidInsights = {
    insights: [
      {
        short: 'You tend to gain energy from engaging with people',
        long: 'You can come across social and energetic especially in activities you are passionate about, which can naturally engage people. You can thrive in roles that involve communication, networking, and interacting with others.',
      },
      {
        short: 'You are likely to come across as cooperative and agreeable in interactions with people',
        long: 'You naturally prefer to maintain harmony and keep peaceful relationships, which tends to make you valuable at diffusing tension and fostering consensus when working with others.',
      },
      {
        short: 'You are inclined towards maintaining work ethic and getting things done',
        long: 'You tend to be comfortable with planning, implementing and attention to detail. Others may perceive you as task focused, dependable and efficient, putting in effort to execute and progress towards goals.',
      },
    ],
    insightType: EInsights.STRENGTHS,
  };

  const pairInsights = {
    personality: [
      {
        attributeName: TAllPersonality.AGREEABLENESS,
        menteeValue: 66,
        mentorValue: 66,
      },
      {
        attributeName: TAllPersonality.EXTRAVERSION,
        menteeValue: 25,
        mentorValue: 35
      },
      {
        attributeName: TAllPersonality.OPENNESS,
        menteeValue: 30,
        mentorValue: 79
      },
      {
        attributeName: TAllPersonality.CONSCIENTIOUSNESS,
        menteeValue: 78,
        mentorValue: 44
      },
      {
        attributeName: TAllPersonality.HUMILITY,
        menteeValue: 31,
        mentorValue: 55
      },
      {
        attributeName: TAllPersonality.EMOTIONALITY,
        menteeValue: 30,
        mentorValue: 10
      },
    ],
    values: [
      {
        attributeName: EValues.CON,
        menteeValue: 30,
        mentorValue: 33
      },
      {
        attributeName: EValues.SD,
        menteeValue: 39,
        mentorValue: 98
      },
      {
        attributeName: EValues.UNI,
        menteeValue: 3,
        mentorValue: 78
      },
    ],
    insights: {
      [EInsights.SHARED_STRENGTHS]: {
        ...actualPaidInsights,
        insightType: EInsights.SHARED_STRENGTHS,
      },
      [EInsights.VARYING_STYLES]: {
        ...actualPaidInsights,
        insightType: EInsights.VARYING_STYLES,
      },
      [EInsights.SHARED_MOTIVATORS]: {
        ...actualPaidInsights,
        insightType: EInsights.SHARED_MOTIVATORS,
      },
      [EInsights.VARYING_VALUES]: {
        ...actualPaidInsights,
        insightType: EInsights.VARYING_VALUES,
      },
    },
  };

  const insights = pairInsights;


  return (
    <PageLayout pageTitle="Match Insights">
      <BrancherDialog
        setClose={() => setOpenRequestUnlock(false)}
        open={openRequestUnlock}
        labelledBy="unlock match insights"
        contentWidth
        title="Would you like to 'Request to Unlock' this insight?"
      >
        <Grid container justify="center">
          <Grid container item>
            <Text variant="md" marginBottom={50} marginTop={20}>
              This will notify your program administrator that you would like to see this insight.
            </Text>
          </Grid>
          <Grid item xs={12} md={8} container justify="space-between">
            <RejectButton
              onClick={() => setOpenRequestUnlock(false)}
            >
              No
            </RejectButton>
            <AcceptButton
              onClick={setSendOpenRequestUnlock}
            >
              Yes
            </AcceptButton>
          </Grid>
        </Grid>
      </BrancherDialog>
      <Grid container justify="center">
        <Grid container justify="space-between" spacing={2}>
          <Grid item container xs={12} lg={6}>
            <BrancherCard>
              <Grid item xs={12}>
                <Text variant="lg" fontWeight={700}>
                  {paid ? 'Our Personality' : 'Top 3 Personality matches'}
                </Text>
              </Grid>
              <Grid item container xs={12} className={styles.flatCard}>
                {paid ? (
                  <PersonalityScaler personalityScales={pairInsights.personality} isMatch />
                ) : (
                  <>
                    <SimpleMatchInsights personalityMatch={['d', 'e', 'f']} />
                    <Grid item xs={12}>
                      <Grid item container justify="space-between" xs={12}>
                        <Grid item alignItems="center" container>
                          <Text variant="xxs" display="inline">Want to know more? </Text>
                          <BrancherLinkText onClick={() => setOpenRequestUnlock(true)} underline="always" display="inline" variant="xxs" fontWeight={600} color="purple">
                            Request to Unlock
                          </BrancherLinkText>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )}
              </Grid>
            </BrancherCard>
          </Grid>
          <Grid item container xs={12} lg={6} spacing={2}>
            <Grid container item xs={12}>
              <InsightsCard {...insights?.insights?.[EInsights.SHARED_STRENGTHS]} />
            </Grid>
            <Grid container item xs={12}>
              <InsightsCard {...insights?.insights?.[EInsights.VARYING_STYLES]} />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <BrancherDivider marginTop={50} marginBottom={50} />
          </Grid>
          <Grid container justify="center">
            <Grid container justify="space-between" spacing={2}>
              <Grid item container xs={12} lg={6}>
                <BrancherCard>
                  <Grid item xs={12}>
                    <Text variant="lg" fontWeight={700} display="inline">
                      {paid ? 'Our Personality' : 'Top 3 Value matches'}
                    </Text>
                  </Grid>
                  <Grid container item>
                    {paid ? (
                      <BrancherRadarChart />
                    ) : (
                      <>
                        <SimpleMatchInsights valuesMatch={['a', 'b', 'c']} />
                        <Grid item xs={12}>
                          <Grid item container justify="space-between" xs={12}>
                            <Grid item alignItems="center" container>
                              <Text variant="xxs" display="inline">Want to know more? </Text>
                              <BrancherLinkText onClick={() => setOpenRequestUnlock(true)} underline="always" display="inline" variant="xxs" fontWeight={600} color="purple">
                                Request to Unlock
                              </BrancherLinkText>
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </BrancherCard>
              </Grid>
              <Grid container item xs={12} lg={6} spacing={2}>
                <Grid container item xs={12}>
                  <InsightsCard {...insights?.insights?.[EInsights.SHARED_MOTIVATORS]} />
                </Grid>
                <Grid container item xs={12}>
                  <InsightsCard {...insights?.insights?.[EInsights.VARYING_VALUES]} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </PageLayout>
  );
};
