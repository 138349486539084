import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import Launch from '@material-ui/icons/Launch';
import { useSelector } from 'react-redux';
import { IStoreTypes } from '../../store/storeTypes';
import { Text } from '../../Components/General/Text';
import { GetIllustrationLink } from '../../utils/LinkUtils';
import { EInsights, IAttributeInsights } from '../../store/reducers/UserInfoReducer';
import { Colors } from '../../consts/colors';
import { BrancherList } from '../../Components/General/BrancherList';
import { BrancherDialog } from '../../Components/General/BrancherDialog';
import { BrancherLinkText } from '../../Components/General/BrancherLinkText';
import { AcceptButton, RejectButton } from '../../Components/InputFields/BrancherButton';

const useStyles = makeStyles({
  insightsCard: {
    borderRadius: 25,
    boxShadow: `0px 5px 15px ${Colors.cardBorder}`,
    padding: 0,
    maxHeight: (props: { showInsight: boolean; phablet: boolean; }) => props.phablet ? 600 : 300,
  },
  insightsImage: {
    width: '100%',
    maxHeight: (props: { showInsight: boolean; phablet: boolean; }) => props.phablet ? 600 : 300,
    borderRadius: `15px 0px 0px 15px`,
  },
  popOutInsightsImage: {
    width: '100%',
    maxHeight: 400,
    height: 400,
  },
  moreInfo: {
    borderRadius: '50%',
    backgroundColor: Colors.purple,
    width: 24,
    height: 24,
  },
  attributeInsights: {
    padding: 30,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  popOutAttributeInsights: {
    padding: 30,
  },
  attributeInsight: {
    filter: (props: { showInsight: boolean; phablet: boolean; }) => !props.showInsight && 'blur(4px)',
  },
  readMore: {
    background: Colors.backgroundDarkGrey,
    borderRadius: 8,
    padding: 8,
  },
});

const insightsContentMapping: {
  [x in EInsights]: {
    image: string;
    title: string;
    subtitle: string;
    showReadMore?: boolean;
    showInfo?: boolean;
  }
} = {
  [EInsights.STRENGTHS]: {
    image: 'strengths.png',
    title: 'Strengths',
    subtitle: 'What you do best',
    showReadMore: true,
  },
  [EInsights.WATCHOUTS]: {
    image: 'watchouts.png',
    title: 'Watch outs',
    subtitle: 'Potential blindspots',
    showReadMore: true,
  },
  [EInsights.MOTIVATORS]: {
    image: 'motivators.png',
    title: 'Motivators',
    subtitle: 'Things that energise you',
    showInfo: true,
  },
  [EInsights.DRAINERS]: {
    image: 'drainers.png',
    title: 'Drainers',
    subtitle: 'Things that frustrate or stress you',
    showInfo: true,
  },
  [EInsights.SHARED_STRENGTHS]: {
    image: 'sharedStrengths.png',
    title: 'Shared Strengths',
    subtitle: 'Where we are similar',
    showInfo: true,
  },
  [EInsights.VARYING_STYLES]: {
    image: 'varyingValueStyles.png',
    title: 'Varying Styles',
    subtitle: 'Where we differ',
    showInfo: true,
  },
  [EInsights.SHARED_MOTIVATORS]: {
    image: 'sharedMotivators.png',
    title: 'Shared Motivators',
    subtitle: 'Where we are similar',
    showInfo: true,
  },
  [EInsights.VARYING_VALUES]: {
    image: 'varyingValueStyles.png',
    title: 'Varying Values',
    subtitle: 'Where we differ',
    showInfo: true,
  },
};

const paidInsights = [EInsights.DRAINERS, EInsights.WATCHOUTS, EInsights.VARYING_STYLES, EInsights.VARYING_VALUES];

export const InsightsCard: React.FC<IAttributeInsights> = (props) => {
  const { insights, insightType } = props;
  const phablet = useMediaQuery(useTheme().breakpoints.down('md'));
  const [openReadMore, setOpenReadMore] = React.useState<boolean>(false);
  const [openInfo, setOpenInfo] = React.useState<boolean>(false);
  const [openRequestUnlock, setOpenRequestUnlock] = React.useState<boolean>(false);
  const [unlocked, setUnlocked] = React.useState<boolean>(false); // for testing locally
  const paid = useSelector((state: IStoreTypes) => state.user?.paidInsights);
  const isPaidInsight = paidInsights.includes(insightType);
  const showInsight = paid || unlocked || !isPaidInsight; // for testing locally
  const styles = useStyles({ showInsight, phablet });

  const setSendOpenRequestUnlock = () => {
    setUnlocked(true);
    setOpenRequestUnlock(false);
    // make api call out to new API that puts a flag on the user profile that says they want to see insights
  };
  const title = insightsContentMapping[insightType].title;
  const subtitle = insightsContentMapping[insightType].subtitle;
  const insightsImage = GetIllustrationLink(insightsContentMapping[insightType].image);
  const showReadMore = insightsContentMapping[insightType]?.showReadMore;
  const showInfo = insightsContentMapping[insightType]?.showInfo;

  return (
    <Grid container justify="center" item xs={12} className={styles.insightsCard}>
      <BrancherDialog
        setClose={() => setOpenReadMore(false)}
        open={openReadMore}
        labelledBy={`${title} ${subtitle}`}
        contentWidth
      >
        <InsightsPopOut
          insights={insights}
          title={title}
          subtitle={subtitle}
          insightsImage={insightsImage}
          insightType={insightType}
          showInsight={showInsight}
        />
      </BrancherDialog>
      <BrancherDialog
        setClose={() => setOpenInfo(false)}
        open={openInfo}
        labelledBy={`${title} ${subtitle}`}
        contentWidth
      >
        More info about {title}
      </BrancherDialog>
      <BrancherDialog
        setClose={() => setOpenRequestUnlock(false)}
        open={openRequestUnlock}
        labelledBy={`${title} ${subtitle}`}
        contentWidth
        title="Would you like to 'Request to Unlock' this insight?"
      >
        <Grid container justify="center">
          <Grid container item>
            <Text variant="md" marginBottom={50} marginTop={20}>
              This will notify your program administrator that you would like to see this insight.
            </Text>
          </Grid>
          <Grid item xs={12} md={8} container justify="space-between">
            <RejectButton
              onClick={() => setOpenRequestUnlock(false)}
            >
              No
            </RejectButton>
            <AcceptButton
              onClick={setSendOpenRequestUnlock}
            >
              Yes
            </AcceptButton>
          </Grid>
        </Grid>
      </BrancherDialog>
      <Grid container item xs={3}>
        <Grid container item xs={12}>
          <img
            src={insightsImage}
            alt={insightType}
            className={styles.insightsImage}
          />
        </Grid>
      </Grid>
      <Grid container item xs={9} className={styles.attributeInsights}>
        <Grid item xs={12}>
          <Grid item container justify="space-between" xs={12}>
            <Grid item>
              <Text variant="xl" fontWeight={600}>
                {title}:
              </Text>
            </Grid>
            <Grid item>
              {showReadMore && showInsight ? (
                <Grid item alignItems="center" container className={styles.readMore} onClick={() => setOpenReadMore(showInsight)}>
                  <Text variant="xxs" display="inline" fontWeight={600} color="purple" marginRight={8}>
                    Read More
                  </Text>
                  <Launch />
                </Grid>
              ) : showInfo && showInsight ? (
                <Grid item alignItems="center" container onClick={() => setOpenInfo(true)}>
                  <Text variant="sm" fontWeight={600} color="white" className={styles.moreInfo} align="center">
                    ?
                  </Text>
                </Grid>
              ) : (showReadMore || showInfo) && !showInsight && (
                <Grid item alignItems="center" container>
                  <BrancherLinkText onClick={() => setOpenRequestUnlock(true)} underline="always" variant="xxs" fontWeight={600} color="purple">
                    Request to Unlock
                  </BrancherLinkText>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Text variant="xxs" fontWeight={600} color="placeholderGrey">
              {subtitle}
            </Text>
          </Grid>
        </Grid>
        <Grid item xs={12} className={styles.attributeInsight}>
         <BrancherList listItems={insights.map((a) => a.short)} variant="xxs" fontWeight={500} />
        </Grid>
      </Grid>
    </Grid>
  );
};

interface IInsightsPopOut {
  insights: IAttributeInsights['insights'];
  insightType: EInsights;
  insightsImage: string;
  title: string;
  subtitle: string;
  showInsight: boolean;
}

const InsightsPopOut: React.FC<IInsightsPopOut> = (props) => {
  const { insights, insightType, insightsImage, showInsight, subtitle, title } = props;
  const mobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const styles = useStyles({ showInsight, mobile });

  return (
    <Grid container alignItems="center">
      <Grid container item xs={3}>
        <Grid container item xs={12}>
          <img
            src={insightsImage}
            alt={insightType}
            className={styles.popOutInsightsImage}
          />
        </Grid>
      </Grid>
      <Grid container xs={9} className={styles.popOutAttributeInsights}>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <Text variant="xl" fontWeight={600}>
              {title}: {subtitle}
            </Text>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <BrancherList
            listItems={insights.map((a) => (
              <Grid container item xs={12}>
                <Grid item xs={12}>
                  <Text
                    variant="xs"
                    fontWeight={600}
                    marginTop={20}
                  >
                    {a.short}
                  </Text>
                </Grid>
                <Grid item xs={12}>
                  <Text
                    marginTop={10}
                    variant="xxs"
                    fontWeight={400}
                  >
                    {a.long}
                  </Text>
                </Grid>
              </Grid>
            ))}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
