import * as React from 'react';
import Chart, { Props } from 'react-apexcharts';
import { EValues, ValuesBrancherNames } from '../../Form/Values/ValuesFormConfig';
import { Colors } from '../../consts/colors';

interface IBrancherRadarChart extends Props {
  dataSeries?: {

  };
}

// https://apexcharts.com/docs/chart-types/radar/
export const BrancherRadarChart: React.FC<IBrancherRadarChart> = (props) => {
  const { } = props;

  const a = {
    options: {
      // https://apexcharts.com/docs/options/grid/
      grid: {
        padding: {
          top: -50,
          bottom: -40,
        }
      },
      chart: {
        width: '100%',
        id: 'value-insights',
        toolbar: {
          show: false
        },
      },
      legend: {
        position: 'top',
        itemMargin: {
          vertical: 0,
        },
      },
      markers: {
        size: 3,
        tooltip: {
          enabled: false,
          offsetX: 0,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      plotOptions: {
        radar: {
          polygons: {
            strokeColors: Colors.chartLines,
            fill: {
              colors: [Colors.backgroundLightPurple]
            }
          }
        }
      },
      yaxis: {
        show: false,
        lines: {
          show: false
        },
        labels: {
          show: false,
          formatter: (value) => '',
        },
      },
      xaxis: {
        labels: {
          show: true,
          formatter: (value) => value,
        },
        categories: [
          ValuesBrancherNames[EValues.CON],
          ValuesBrancherNames[EValues.SD],
          ValuesBrancherNames[EValues.SEC],
          ValuesBrancherNames[EValues.POW],
          ValuesBrancherNames[EValues.UNI],
          ValuesBrancherNames[EValues.ACH],
          ValuesBrancherNames[EValues.TRA],
          ValuesBrancherNames[EValues.STI],
          ValuesBrancherNames[EValues.HED],
          ValuesBrancherNames[EValues.BEN]
        ]
      }
    },
    series: [
      {
        name: 'You',
        data: [30, 40, 35, 50, 49, 40, 70, 41, 35, 44]
      },
      {
        name: 'Match',
        data: [31, 2, 15, 50, 59, 60, 40, 91, 15, 88]
      },
    ],
  } as any;

  return (
    <div id="value-insights" style={{ width: '100%', marginBottom: -60 }}>
      <Chart
        options={a.options}
        series={a.series}
        width="100%"
        type="radar"
      />
    </div>
  );
};

