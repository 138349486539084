import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';
import { IStoreTypes } from '../../store/storeTypes';
import { InsightsCard } from './InsightsCard';
import { BrancherCard } from '../../Components/General/BrancherCard';
import { EInsights, TAllPersonality } from '../../store/reducers/UserInfoReducer';
import { Text } from '../../Components/General/Text';
import { BrancherDivider } from '../../Components/General/BrancherDivider';
import { PageLayout } from '../../Components/General/PageLayout';
import { PersonalityScaler } from './PersonalityScaler';
import { EValues } from '../../Form/Values/ValuesFormConfig';
import { BrancherRadarChart } from '../../Components/General/BrancherRadarChart';

interface IInsights {
  match?: boolean;
}

export const Insights: React.FC<IInsights> = ( { match } ) => {
  const pairInsights = useSelector((state: IStoreTypes) => state.user?.sessionPairInsights);
  // const myInsights = useSelector((state: IStoreTypes) => state.user?.myInsights);

  const actualInsights = {
    insights: [
      {
        short: 'You tend to gain energy from engaging with people',
        long: 'You can come across social and energetic especially in activities you are passionate about, which can naturally engage people. You can thrive in roles that involve communication, networking, and interacting with others.',
      },
      {
        short: 'You are likely to come across as cooperative and agreeable in interactions with people',
        long: 'You naturally prefer to maintain harmony and keep peaceful relationships, which tends to make you valuable at diffusing tension and fostering consensus when working with others.',
      },
      {
        short: 'You are inclined towards maintaining work ethic and getting things done',
        long: 'You tend to be comfortable with planning, implementing and attention to detail. Others may perceive you as task focused, dependable and efficient, putting in effort to execute and progress towards goals.',
      },
    ],
    insightType: EInsights.STRENGTHS,
  };

  const myInsights = {
    personality: [
      {
        attributeName: TAllPersonality.AGREEABLENESS,
        menteeValue: 66,
        mentorValue: 66,
      },
      {
        attributeName: TAllPersonality.EXTRAVERSION,
        menteeValue: 25,
        mentorValue: 35
      },
      {
        attributeName: TAllPersonality.OPENNESS,
        menteeValue: 30,
        mentorValue: 79
      },
      {
        attributeName: TAllPersonality.CONSCIENTIOUSNESS,
        menteeValue: 78,
        mentorValue: 44
      },
      {
        attributeName: TAllPersonality.HUMILITY,
        menteeValue: 31,
        mentorValue: 55
      },
      {
        attributeName: TAllPersonality.EMOTIONALITY,
        menteeValue: 30,
        mentorValue: 10
      },
    ],
    values: [
      {
        attributeName: EValues.CON,
        menteeValue: 30,
        mentorValue: 33
      },
      {
        attributeName: EValues.SD,
        menteeValue: 39,
        mentorValue: 98
      },
      {
        attributeName: EValues.UNI,
        menteeValue: 3,
        mentorValue: 78
      },
    ],
    insights: {
      [EInsights.DRAINERS]: {
        ...actualInsights,
        insightType: EInsights.DRAINERS,
      },
      [EInsights.WATCHOUTS]: {
        ...actualInsights,
        insightType: EInsights.WATCHOUTS,
      },
      [EInsights.STRENGTHS]: {
        ...actualInsights,
        insightType: EInsights.STRENGTHS,
      },
      [EInsights.MOTIVATORS]: {
        ...actualInsights,
        insightType: EInsights.MOTIVATORS,
      },
    },
  };

  const insights = match ? pairInsights : myInsights;

  return (
    <PageLayout pageTitle="Insights">
      <Grid container justify="center">
        <Grid container justify="space-between" spacing={2}>
          <Grid item container xs={12} lg={6}>
            <BrancherCard>
              <Grid item xs={12}>
                <Text variant="lg" fontWeight={700}>
                  My Personality
                </Text>
              </Grid>
              <Grid item container xs={12}>
                <PersonalityScaler personalityScales={myInsights.personality} isMatch={false} />
              </Grid>
            </BrancherCard>
          </Grid>
          <Grid item container xs={12} lg={6} spacing={2}>
            <Grid container item xs={12}>
              <InsightsCard {...insights?.insights?.[EInsights.STRENGTHS]} />
            </Grid>
            <Grid container item xs={12}>
              <InsightsCard {...insights?.insights?.[EInsights.WATCHOUTS]} />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <BrancherDivider marginTop={50} marginBottom={50} />
          </Grid>
          <Grid container justify="center">
            <Grid container justify="space-between" spacing={2}>
              <Grid item container xs={12} lg={6}>
                <BrancherCard>
                  <Grid item xs={12}>
                    <Text variant="lg" fontWeight={700} marginBottom={-100}>
                      My Values
                    </Text>
                  </Grid>
                  <Grid container item>
                    <BrancherRadarChart />
                  </Grid>
                </BrancherCard>
              </Grid>
              <Grid container item xs={12} lg={6} spacing={2}>
                <Grid container item xs={12}>
                  <InsightsCard {...insights?.insights?.[EInsights.MOTIVATORS]} />
                </Grid>
                <Grid container item xs={12}>
                  <InsightsCard {...insights?.insights?.[EInsights.DRAINERS]} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <BrancherDivider marginTop={50} marginBottom={50} />
          </Grid>
          <Grid container justify="center">
            <Grid container justify="space-between" spacing={2}>
              <Grid item container xs={12}>
                <BrancherCard>
                  <Grid container item>
                    <Text variant="lg" fontWeight={700}>
                      My Development Goals
                    </Text>
                  </Grid>
                </BrancherCard>
              </Grid>
              <Grid container item xs={12}>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </PageLayout>
  );
};
