import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import { GetIllustrationLink } from '../../utils/LinkUtils';
import { BrancherList } from '../../Components/General/BrancherList';
import { Colors } from '../../consts/colors';

const useStyles = makeStyles({
  insightsImage: {
    width: '100%',
    maxHeight: (props: { showInsight: boolean; phablet: boolean; }) => props.phablet ? 600 : 300,
    height: (props: { showInsight: boolean; phablet: boolean; }) => props.phablet ? 500 : 300,
    borderRadius: `15px 0px 0px 15px`,
  },
  attributeInsights: {
    padding: 30,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  flatCard: {
    maxHeight: (props: { showInsight: boolean; phablet: boolean; }) => props.phablet ? 600 : 300,
    height: (props: { showInsight: boolean; phablet: boolean; }) => props.phablet ? 500 : 300,
    marginBottom: 20,
    borderRadius: 15,
    background: Colors.backgroundDarkGrey,
  },
});

interface ISimpleMatchInsights {
  personalityMatch?: string[];
  valuesMatch?: string[];
}

export const SimpleMatchInsights: React.FC<ISimpleMatchInsights> = (props) => {
  const { personalityMatch, valuesMatch } = props;
  const phablet = useMediaQuery(useTheme().breakpoints.down('md'));
  const styles = useStyles({ phablet });

  const isPersonalityType = !!personalityMatch;
  const insights = isPersonalityType ? personalityMatch : valuesMatch;
  const insightType = isPersonalityType ? 'personality' : 'values';

  const simpleContentsMapping = {
    personality: {
      image: 'simpleMatchPersonality.png'
    },
    values: {
      image: 'simpleMatchValues.png'
    },
  };

  const insightsImage = GetIllustrationLink(simpleContentsMapping[insightType].image);

  return (
    <Grid container justify="center" item xs={12} className={styles.flatCard}>
      <Grid container item xs={5}>
        <Grid container item xs={12}>
          <img
            src={insightsImage}
            alt={insightType}
            className={styles.insightsImage}
          />
        </Grid>
      </Grid>
      <Grid container item xs={7} className={styles.attributeInsights}>
        <Grid item xs={12}>
          <BrancherList listItems={insights} variant="xxs" fontWeight={500} />
        </Grid>
      </Grid>
    </Grid>
  );
};
