import { IValidationRules } from '../../utils/validators/validatePassword';
import { IReducerType } from './ReducerType';
import { EPlatformTypes } from '../../consts/PlatformTypes';
import { ISubscriptions } from '../../types/SubscriptionTypes';
import { ProgramPositions } from '../../QualifyingForm/ProgramPositionOptions';
import { EValues } from '../../Form/Values/ValuesFormConfig';

export const SAVE_USER_INFO = 'SAVE_USER_INFO';
export const CLEAR_USER_INFO = 'CLEAR_USER_INFO';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_REDIRECT = 'USER_REDIRECT';
export const USER_CELEBRATE = 'USER_CELEBRATE';

export const UserInfoReducer = (state = initialState, action: IReducerType) => {
  switch (action.type) {
    case SAVE_USER_INFO:
      return { ...state, ...action.payload };
    case CLEAR_USER_INFO:
      return initialState;
    case USER_LOGOUT:
      return { ...state, loggedIn: false };
    case USER_REDIRECT:
      return { ...state, redirected: action.payload };
    case USER_CELEBRATE:
      return { ...state, celebrate: action.payload };
    default:
      return state;
  }
};

export enum EPairingTypes {
  BRANCHER_RECOMMENDED = 'BRANCHER_RECOMMENDED', // used Brancher's recommendation
  TARGET_USER_RECOMMENDED = 'TARGET_USER_RECOMMENDED', // targeted user and use Brancher's recommendation once targeted
  NO_ALGORITHM = 'NO_ALGORITHM', // target user and ignored algorithm
}

export interface IPairReason {
  attributeName: string; // variable name - for personality and values. These are 'personality' and 'values'
  readableName: string;
  percentage: string;
  reasons: string[]; // For personality and values these are the attributes
}

export interface IPairInfo {
  name: string;
  roleId: string;
  programId: string;
  pairingReasons?: {
    custom?: IPairReason[];
    personality?: IPairReason[];
    values?: IPairReason[];
  };
  userId?: string;
}

export enum ProgramRoleIds {
  mentee = 'menteeRoleId',
  mentor = 'mentorRoleId',
  peer = 'peerRoleId',
  catchups = 'catchupsRoleId',
}

export interface IDecodedUserTokenAttributes {
  id?: string;
  email?: string;
  username?: string;
  phoneNumber?: string;
  tokenExp?: number;
  IDToken?: string;
}

export enum UpdatableCompanyInfo {
  COMPANY_NAME = 'companyName',
  PROGRAM_IDS = 'programIds',
  GOOGLE_SSO = 'googleSignOn',
  SAML_SSO = 'samlSignOn',
  PASSWORD_AUTH = 'passwordSignOn',
  CUSTOM_LOGO = 'customLogo',
  WHITE_LABEL = 'whiteLabel',
  DOMAIN_EMAIL = 'domainEmail',
  PASSWORD_VALIDATION = 'passwordValidation',
  MFA_ENABLED = 'mfaEnabled',
}

export interface ICompanyInfo {
  [UpdatableCompanyInfo.COMPANY_NAME]?: string;
  [UpdatableCompanyInfo.PROGRAM_IDS]?: string[];
  [UpdatableCompanyInfo.GOOGLE_SSO]?: boolean;
  [UpdatableCompanyInfo.PASSWORD_AUTH]?: boolean;
  [UpdatableCompanyInfo.SAML_SSO]?: boolean;
  [UpdatableCompanyInfo.CUSTOM_LOGO]?: string;
  [UpdatableCompanyInfo.WHITE_LABEL]?: boolean;
  [UpdatableCompanyInfo.PASSWORD_VALIDATION]?: IValidationRules;
  companyId?: string;
}

export interface IUserMentoringRequest {
  mentoringRequestId: string;
  programId: string;
  initiatorPosition: ProgramPositions;
  initiatorRoleId: string;
}

export interface IUserGroups {
  roleId: string;
  programId: string;
  groupId: string;
}

export enum EComponentType {
  TOGGLE = 'toggle',
  TEXT = 'text',
  SLIDER = 'slider',
  INFORMATION = 'information',
  DND = 'dnd',
}

export enum EUserActions {
  CHECK_IN_SURVEY = 'checkInSurvey',
  FINISHING_SURVEY = 'finishingSurvey',
  WRAP_UP_SURVEY = 'wrapUpSurvey',
  CUSTOM_SURVEY = 'customSurvey',
  POST_WRAP_UP_MENTEE_SURVEY = 'postWrapUpMenteeSurvey', // this is a client customised survey
  POST_WRAP_UP_MENTOR_SURVEY = 'postWrapUpMentorSurvey', // this is a client customised survey
  // From nudges
  MEETING_SURVEY = 'meetingSurvey', // A user is prompted to fill out this one click survey after a meeting
  STAGNANT_GOALS = 'stagnantGoals', // HAS_NOT_UPDATED_GOALS
  CREATE_MEETING = 'createMeeting', // HAS_NOT_MET_MENTORING_PARTNER
  UPLOAD_PROFILE_PIC = 'uploadProfilePic', // HAS_NOT_UPLOADED_PROFILE_PIC
  FIND_MENTORING_PARTNER = 'findMentoringPartner', // HAS_NO_MENTORING_PARTNER
  COMPLETE_TRAINING = 'completeTraining', // HAS_NOT_COMPLETED_TRAINING
  COMPLETE_MENTORING_AGREEMENT = 'completeMentoringAgreement', // HAS_NOT_COMPLETED_MENTORING_AGREEMENT
}

export const UserActionTitles = {
  [EUserActions.CHECK_IN_SURVEY]: (programStyle?: string) => 'Complete your check-in survey',
  [EUserActions.FINISHING_SURVEY]: (programStyle?: string) => 'Complete your finishing-up survey',
  [EUserActions.CUSTOM_SURVEY]: (programStyle?: string) => 'Complete this survey',
  [EUserActions.WRAP_UP_SURVEY]: (partnerName: string, programStyle: string) =>
    `Fill out a quick wrap-up survey about your ${programStyle} relationship with ${partnerName}`,
  [EUserActions.POST_WRAP_UP_MENTEE_SURVEY]: (programStyle: string) =>
    `Complete this final ${programStyle} relationship survey`,
  [EUserActions.POST_WRAP_UP_MENTOR_SURVEY]: (programStyle: string) =>
    `Complete this final ${programStyle} relationship survey`,
  // Nudge based
  [EUserActions.MEETING_SURVEY]: (programStyle?: string) => 'Meeting check-in',
  [EUserActions.STAGNANT_GOALS]: (programStyle?: string) => 'Review your goals',
  [EUserActions.FIND_MENTORING_PARTNER]: (partnerPosition: string) => `Find a ${partnerPosition}`,
  [EUserActions.UPLOAD_PROFILE_PIC]: (programStyle?: string) => 'Upload a profile picture',
  [EUserActions.COMPLETE_TRAINING]: (programStyle?: string) => 'Complete training',
  // These 2 should be dynamic, but have a fallback
  [EUserActions.CREATE_MEETING]: (programStyle?: string) => 'Report a meeting',
  [EUserActions.COMPLETE_MENTORING_AGREEMENT]: (programStyle: string) =>
    `Complete your ${programStyle} agreement`,
};

export const ActionButtonTitles = {
  [EUserActions.CHECK_IN_SURVEY]: () => 'Start check-in',
  [EUserActions.FINISHING_SURVEY]: () => 'Start survey',
  [EUserActions.CUSTOM_SURVEY]: () => 'Start survey',
  [EUserActions.WRAP_UP_SURVEY]: () => `Start survey`,
  [EUserActions.POST_WRAP_UP_MENTEE_SURVEY]: () => `Start survey`,
  [EUserActions.POST_WRAP_UP_MENTOR_SURVEY]: () => `Start survey`,
  [EUserActions.MEETING_SURVEY]: () => 'Rate meeting',
  [EUserActions.STAGNANT_GOALS]: () => 'Review',
  [EUserActions.FIND_MENTORING_PARTNER]: () => `Search`,
  [EUserActions.UPLOAD_PROFILE_PIC]: () => 'Upload',
  [EUserActions.COMPLETE_TRAINING]: () => 'Complete',
  [EUserActions.CREATE_MEETING]: () => 'Schedule',
  [EUserActions.COMPLETE_MENTORING_AGREEMENT]: () => `Complete`,
};

export interface IUserAction {
  userActionId: string;
  programId: string;
  type: EUserActions;
  roleId: string; // this is used to filter on the sessionRoleId in the FE
  canDismissAction: boolean; // These are for nudge oriented actions
  title?: string;
  dueDate?: string;
  actionAttributes?: {
    // for wrap-up survey - TODO: refactor into proper interfaces
    menteeRoleId: string;
    mentorRoleId: string;
    partnerRoleId?: string; // Added 17/5
    partnerName?: string;
    // for meeting survey
    meetingId?: string;
  };
  isSurvey?: boolean;
  surveyId?: string;
  isCustom?: boolean; // these are client surveys with custom questions
  requiresAuthentication?: boolean; // some surveys may not need authentication - deep link?
}

export enum EInsights {
  // personal
  STRENGTHS = 'strengths',
  WATCHOUTS = 'watchouts',
  MOTIVATORS = 'motivators',
  DRAINERS = 'drainers',
  // paired
  SHARED_STRENGTHS = 'sharedStrengths',
  VARYING_STYLES = 'varyingStyles',
  SHARED_MOTIVATORS = 'sharedMotivators',
  VARYING_VALUES = 'varyingValues',
}

export interface IAttributeInsights {
  insights: Array<{
    short: string;
    long: string;
  }>;
  insightType: EInsights;
}

export enum TAllPersonality {
  AGREEABLENESS = 'agreeableness',
  EXTRAVERSION = 'extraversion',
  CONSCIENTIOUSNESS = 'conscientiousness',
  OPENNESS = 'openness',
  HUMILITY = 'humility',
  EMOTIONALITY = 'emotionality',
}

export type IPersonalityAttributeInsights = Array<{
  attributeName: TAllPersonality;
  menteeValue?: number;
  mentorValue?: number;
}>;

export type IValuesAttributeInsights = Array<{
  attributeName: EValues;
  menteeValue?: number;
  mentorValue?: number;
}>;

export interface IUserInfo extends IDecodedUserTokenAttributes, ICompanyInfo {
  sessionPairInsights?: {
    personality: IPersonalityAttributeInsights;
    values: IValuesAttributeInsights;
    insights: {
      [EInsights.SHARED_STRENGTHS]: IAttributeInsights;
      [EInsights.VARYING_STYLES]: IAttributeInsights;
      [EInsights.SHARED_STRENGTHS]: IAttributeInsights;
      [EInsights.VARYING_VALUES]: IAttributeInsights;
    };
  };
  myInsights?: {
    personality: Array<{
      readableName: string;
      value: number;
    }>;
    values: Array<{
      readableName: string;
      value: number;
    }>;
    insights: {
      [EInsights.DRAINERS]: IAttributeInsights;
      [EInsights.WATCHOUTS]: IAttributeInsights;
      [EInsights.STRENGTHS]: IAttributeInsights;
      [EInsights.MOTIVATORS]: IAttributeInsights;
    };
  };
  accessToken?: string;
  refreshToken?: string;
  firstName?: string;
  lastName?: string;
  dob?: string;
  loggedIn?: boolean;
  paidInsights?: boolean;
  celebrateProgressActivity?: boolean;
  hasAzureCalendar?: boolean;
  hasGoogleCalendar?: boolean;
  menteeGetStartedProgress?: {
    hasCompletedTraining?: boolean;
    hasExploredCohort?: boolean;
    hasConnectedCalendar?: boolean;
    hasSentMentoringRequest?: boolean;
  };
  mentorGetStartedProgress?: {
    hasCompletedTraining?: boolean;
    hasExploredCohort?: boolean;
    hasConnectedCalendar?: boolean;
    hasSentMentoringRequest?: boolean;
  };
  needsReviewing?: boolean;
  forgotPasswordEmail?: string;
  positions?: string[];
  supportEmail?: string;
  redirected?: boolean;
  redirectRoute?: string;
  expiredToken?: boolean;
  loggedInPlatform?: EPlatformTypes.mentoring | '';
  programType?: EPlatformTypes.mentoring | '';
  sessionPair?: IPairInfo;
  mentees?: IPairInfo[];
  mentors?: IPairInfo[];
  isMatched?: boolean;
  hasNumerousRoles?: boolean;
  isSSO?: boolean;
  formConfig?: {
    general?: any[];
    values?: any;
    personality?: any;
    finalising?: any[];
  };
  programId?: string;
  [UpdatableCompanyInfo.CUSTOM_LOGO]?: string;
  programName?: string;
  sessionRoleId?: string;
  [ProgramRoleIds.catchups]?: string;
  [ProgramRoleIds.mentee]?: string;
  [ProgramRoleIds.mentor]?: string;
  sessionPosition?: ProgramPositions;
  roleLabels?: {
    [ProgramPositions.mentee]: string;
    [ProgramPositions.mentor]: string;
    programStyle: string;
  };
  completedProfile?: boolean;
  subscription?: ISubscriptions[];
  modules?: { mentee: string[]; mentor: string[] };
  celebrate?: boolean;
  applicationAlwaysOpen?: boolean;
  requiresAdhocPairing?: boolean;
  requiresApplicationApproval?: boolean;
  hasAdhocPairing?: boolean;
  maximumMentorAmount?: number;
  maximumMenteeAmount?: number;
  mentoringPartnerRequests?: IUserMentoringRequest[];
  actions?: IUserAction[];
  editApplicationForm?: boolean;
  passwordPolicy?: IValidationRules;
  groups?: IUserGroups[];
}

const initialState: IUserInfo = {
  IDToken: '',
  accessToken: '',
  refreshToken: '',
  tokenExp: 0,
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: '',
  username: '',
  id: '',
  loggedIn: false,
  companyId: '',
  [UpdatableCompanyInfo.COMPANY_NAME]: '',
  positions: [],
  roleLabels: {
    [ProgramPositions.mentee]: ProgramPositions.mentee,
    [ProgramPositions.mentor]: ProgramPositions.mentor,
    programStyle: 'mentoring',
  },
  redirected: false,
  expiredToken: false,
  loggedInPlatform: '',
  formConfig: {},
  sessionPosition: null,
  hasNumerousRoles: false,
  isSSO: false,
  programId: '',
  programName: '',
  catchupsRoleId: '',
  menteeRoleId: '',
  mentorRoleId: '',
  sessionRoleId: '',
  subscription: [],
  modules: { mentee: [], mentor: [] },
  celebrate: false,
  applicationAlwaysOpen: false,
  requiresAdhocPairing: false,
  hasAdhocPairing: false,
  editApplicationForm: false,
};
